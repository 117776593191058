<template>
  <div id="help">
    <header>
      <div class="left">
        <img src="../img/板块图标 - 副本_slices/logo.png" alt="" />
      </div>
      <ul class="right">
        <li @click="back">
          <img src="../img/上课/返回.png" alt="" />
          <span>返回</span>
        </li>
        <li>
          <el-dropdown
            trigger="click"
            placement="bottom-start"
            style="height: 50/96in; display: flex"
          >
            <div
              class="el-dropdown-link"
              style="display: flex; align-items: center; height: 30px"
            >
              <img src="../img/上课/帮助 (5).png" alt="" />
              帮助
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <span @click="tohelp">系统通知</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="tohelp2">系统介绍</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <img src="../img/上课/设置 (6).png" alt="" />
          <span>设置</span>
        </li>
        <li @click="out">
          <img src="../img/上课/结束.png" alt="" />
          <span>结束课程</span>
        </li>
      </ul>
    </header>
    <main v-if="this.$route.query.id == 1">
      <div class="box first">
        <div class="title">系统通知</div>
      </div>
    </main>
    <main v-else>
      <div class="box second">
        <div class="title">
          <img src="../img/板块图标 - 副本_slices/111.jpg" alt="" />
          <p>智能在线备课系统</p>
        </div>
        <div class="name">系统名称：智能在线备课平台</div>
        <div class="name">系统版本：1.0版本</div>
        <div class="info">
          系统介绍：在线学习系统将传统教学与网络教学相结合，充分利用互联网信息技术，提升教学管理水平，降低
          教学培训成本。系统主要包括在线学习、在线练习考试、学生管理、班级管理、课程管理等功能，旨在利用网
          络化手段提供了一个面向教员和学员交互的环境。
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router.push("/main");
    },
    out() {
      util.post(
        "/courseEnd",
        {
          md_str: localStorage.getItem("md"),
        },
        (res) => {
          if (res.code == 1) {
            this.$router.push("/main/starts");
          }
        }
      );
    },
    tohelp() {
      this.$router.push({
        path: "/help",
        query: {
          id: 1,
        },
      });
    },
    tohelp2() {
      this.$router.push({
        path: "/help",
        query: {
          id: 2,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
#help {
  header {
    height: 60/96in;
    background-color: #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.35rem /* 35/100 */ 0 0.2rem /* 56/100 */;
    border-bottom: 1px solid #cdcdcd;
    .left {
      display: flex;
      align-items: center;
      img {
        height: 36/96in;
        margin-right: 8px;
      }
    }
    .right {
      display: flex;
      li {
        margin-left: 0.33rem /* 33/100 */;
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
          height: 22/96in;
          margin-right: 7px;
        }
      }
    }
  }
  main {
    display: flex;
    justify-content: center;
    background-color: #eff0f2;
    height: calc(100vh - 0.625in);
    padding: 19px 0 41px;
    .box {
      width: 1000px;
      height: 100%;
      background: #ffffff;
      border-radius: 5px;
    }
    .first {
      .title {
        height: 126px;
        border-bottom: 1px solid #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #131313;
        font-size: 20px;
      }
    }
    .second {
      padding: 0 105px;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      color: #000000;
      .title {
        height: 294px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #f0f0f0;
        img {
          width: 119px;
        }
        p {
          font-size: 20px;
          font-weight: bold;
        }
      }
      .name {
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
        font-weight: 400;
        border-bottom: 1px solid #f0f0f0;
      }
      .info {
        margin-top: 15px;
        font-size: 16px;
        font-weight: 400;

        line-height: 36px;
      }
    }
  }
}
</style>